import { CTAProps } from '@/ts/interfaces/cta';
import { mapClassNames } from '@/helpers/utils/classNames';
import getCapitalizedSocialName from '@/helpers/utils/socials';
import styles from './styles/CTA.module.scss';
import Icon from '../Icon/Icon';

const CTA: React.FC<CTAProps> = ({
  href = '#',
  design,
  social,
  label,
  count,
  disabled,
  icons,
  isLink,
  hideLabel,
  hideSocialIcon,
  ariaLabel,
  dataTrackName,
  dataTmDataTrack,
  dataLinkTracking,
  dataTmDataTrackLocation,
  dataTrackUrl,
  dataTestId,
  title,
  onClick,
  target,
  rel,
}) => {
  let icon = icons?.icon;
  let ctaLabel = label;
  const secondaryIcon = icons?.secondaryIcon;

  if (design === 'social') {
    if (!social) throw new Error(`'social' design requires 'social' prop to be provided.`);
    if (!label) ctaLabel = getCapitalizedSocialName(social);
    if (hideSocialIcon) icon = undefined;
    else icon = social;
  }

  if (design !== 'social' && social) {
    throw new Error(`'social' prop requires 'social' design to be provided.`);
  }

  const shouldApplyRoundClass: boolean = (ctaLabel === '' || !!hideLabel) && !count && !secondaryIcon;

  const Element = isLink ? 'a' : 'button';

  return (
    <Element
      aria-label={ariaLabel}
      href={isLink ? href : undefined}
      disabled={disabled}
      className={mapClassNames(
        styles,
        'cta-base',
        `${shouldApplyRoundClass && 'round'}`,
        design,
        `${social && social.toLowerCase()}`
      )}
      onClick={onClick ? (e) => onClick(e) : undefined}
      data-testid={dataTestId}
      title={title || null || undefined}
      data-tmdatatrack={dataTmDataTrack}
      data-tmdatatrack-name={dataTrackName}
      data-link-tracking={dataLinkTracking}
      data-tmdatatrack-location={dataTmDataTrackLocation}
      data-track-url={dataTrackUrl}
      target={target}
      rel={rel}
    >
      {icon && <Icon name={icon} color={icons?.iconsColor} />}
      {!hideLabel && ctaLabel}
      {secondaryIcon ? (
        <Icon name={secondaryIcon} color={icons?.iconsColor} />
      ) : (
        (count || count === 0) && <span className={styles.count}>{count}</span>
      )}
    </Element>
  );
};

export default CTA;
